import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Casa | Paintball Martesana
			</title>
			<meta name={"description"} content={"Scatena il divertimento, la strategia e l'avventura!"} />
			<meta property={"og:title"} content={"Casa | Paintball Martesana"} />
			<meta property={"og:description"} content={"Scatena il divertimento, la strategia e l'avventura!"} />
			<meta property={"og:image"} content={"https://exilovatebix.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://exilovatebix.com/img/324234.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://exilovatebix.com/img/324234.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://exilovatebix.com/img/324234.png"} />
			<meta name={"msapplication-TileImage"} content={"https://exilovatebix.com/img/324234.png"} />
		</Helmet>
		<Components.Header />
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					padding="10px 10px 10px 10px"
				>
					<Image src="https://exilovatebix.com/img/1.jpg" display="block" width="100%" />
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 52px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
					Benvenuti a Martesana!
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
					Entrate nel mondo esilarante del Paintball Martesana, dove l'eccitazione incontra il cameratismo in ogni partita. Che siate qui per festeggiare un'occasione speciale o per affinare le vostre abilità tattiche, il nostro club si impegna a offrire un'avventura di paintball memorabile e ad alta energia.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 32px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						Informazioni sul Paintball Martesana
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--primary"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							Il Paintball Martesana si dedica a offrire un'esperienza di paintball eccezionale a giocatori di tutti i livelli. Con attrezzature all'avanguardia e una varietà di campi tematici, il nostro club offre l'ambiente perfetto per una giornata piena di strategia, lavoro di squadra e, naturalmente, molti schizzi di vernice! Il nostro impegno per la sicurezza e il divertimento assicura che ogni visita sia uno spasso.
						</Text>
					</Box>
				</Box>
				<Image
					src="https://exilovatebix.com/img/2.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 900 42px/1.2 --fontFamily-mono"
				padding="0px 0px 20px 0px"
				width="100%"
				text-align="center"
			>
				Perché scegliere Martesana?
			</Text>
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Strutture eccezionali: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Godetevi i diversi scenari di gioco nei nostri campi meticolosamente progettati per sfidare ed emozionare.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Attrezzatura di alto livello: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Forniamo le più recenti attrezzature da paintball per garantire che la vostra esperienza sia confortevole e all'avanguardia.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Comunità e cameratismo: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Entrate a far parte di una comunità di facce amiche e di colleghi appassionati. Perfezionate le vostre abilità insieme!
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Box margin="0px 0px 16px 0px" border-radius="50%" padding="5px 5px 5px 5px" position="relative">
						<Box
							width="100%"
							height="100%"
							position="absolute"
							top="0px"
							left="0px"
							background="--color-indigo"
							opacity="0.5"
							border-radius="50%"
						/>
						<Icon
							category="md"
							icon={MdDone}
							color="--primary"
							size="48px"
							padding="6px 6px 6px 6px"
							position="static"
						/>
					</Box>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Divertimento strategico per tutti: 
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Che si tratti di un evento aziendale o di una giornata tra amici, i nostri giochi promuovono il lavoro di squadra e il pensiero strategico.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});